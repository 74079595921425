import React from 'react'
import {Link} from 'react-router-dom'
import {AppBar,Toolbar,Typography,Button,Menu,MenuItem} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {InfoOutlined,Language} from '@material-ui/icons'
import Constant from '../Constant'
import {useTranslation} from 'react-i18next'
import {animated} from '../Util'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}))

const EFFECT = animated('fadeInDown')

export default function Navbar(props) {
  const {name,icon,languages} = Constant
  const classes = useStyles()

  const [anchorEl,setAnchorEl] = React.useState(null)

  const {t,i18n} = useTranslation()

  function handleClick(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const currentLanguage = ()=>{
    const {languages} = i18n
    if (Array.isArray(languages)&&languages.length>0) return languages[0]
    return 'en'
  }

  return (
    <div className={`${classes.root} ${EFFECT}`}>
      <AppBar position="relative">
        <Toolbar>
          <Link to={'/'} className={classes.title}>
            <Typography component="span" variant="h5" color="inherit" noWrap className={`mr-3`}>{icon}</Typography>
            <Typography component="span" variant="h6" color="inherit" noWrap>{name}</Typography>
          </Link>
          <Link to={'/about'}>
            <Button color="inherit"><InfoOutlined /></Button>
          </Link>
          <Button color="inherit" onClick={handleClick}><Language className={`mr-2`} />{currentLanguage()}</Button>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {languages.map((e,i)=>{
              const handleClick = ()=>{
                i18n.changeLanguage(e)
                setAnchorEl(null)
              }
              return (
                <MenuItem key={i} onClick={handleClick}>{t(e)}</MenuItem>
              )
            })}
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  )
}
