import React from 'react'
import Head from '../component/Head'
import Constant from '../Constant'
import {isUrl,animated} from '../Util'
import {useTranslation} from 'react-i18next'

export default function About(props) {
  const {icon,name,lead,about} = Constant
  const {t} = useTranslation()
  return (
    <div className="container">
      <h1 className={`text-center text-light mb-3 ${animated('fadeInDown')}`}>{t('About')}</h1>
      <Head center className={`p-5 mb-5 ${animated()}`}>
        <h1>{icon}</h1>
        <h3>{name}</h3>
        <p className="lead">{lead}</p>
      </Head>

      <div className="p-3 text-center">
        {
          Object.keys(about).map((e,i)=>{
            const v = about[e]
            const render = ()=>{
              if (isUrl(v)) return <a href={v} target="_blank" rel="noopener noreferrer">{v}</a>
              return v
            }
            return (
              <div key={i} className={`mb-5 ${animated()}`}>
                <label className="text-uppercase">{t(e)}</label>
                <h5>{render(v)}</h5>
              </div>
            )
          })
        }
      </div>

    </div>
  )
}
