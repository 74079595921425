import React from 'react'

export default function Head(props) {
  const {className='',center=false,children} = props
  const classNames = ['container p-3 rounded bg-white shadow',className]
  if (center) {
    classNames.push('text-center')
  }
  return (
    <div className={classNames.join(' ')}>{children}</div>
  )
}
