import React from 'react'
import {useTranslation} from 'react-i18next'
import Head from '../component/Head'
import {fromMd,animated} from '../Util'
import Constant from '../Constant'

export default function Terms(props) {
  const {name,about} = Constant
  const {link,email,country} = about

  const {t} = useTranslation()
  const md = t(`terms`,{name,link,email,country})

  return (
    <div className="container mb-5">
      <h1 className={`text-white text-center mb-3 ${animated('fadeInDown','slow')}`}>{t('Terms and Conditions')}</h1>
      <Head className={`p-5 ${animated('fadeInUp')}`}>
        <div dangerouslySetInnerHTML={fromMd(md)} />
      </Head>
    </div>
  )
}
