import React from 'react'
import {Route,Switch} from 'react-router-dom'
import Home from '../page/Home'
import About from '../page/About'
import Constant from '../Constant'

export default function Main(props) {
  return (
    <main role="main">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        {
          Constant.pages.map((e,i)=>{
            const {path,component} = e
            return (
              <Route key={i} exact path={path} component={component} />
            )
          })
        }
      </Switch>
    </main>
  )
}
