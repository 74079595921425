import React from 'react'
import Body from './component/Body'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import './style/style.css'
import './style/material.css'
import './style/media.css'
import './style/override.css'

export default function App(props) {
  return (
    <Body />
  )
}
