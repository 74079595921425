import React from 'react'
import {randomBgGrandientDarkStyle} from '../Util'

export default function Background(props) {
  const {cover,className='',random=false} = props
  if (typeof(cover)==='string'&&cover.length>0) {
    const style = {
      background: `url('/img/cover-overlay.png'),url(${cover}) no-repeat center center`,
      backgroundSize: 'cover',
      height: '100%',
      overflow: 'hidden',
    }
    return <div className={`bg ${className}`} style={style} />
  }
  if (random) {
    const background = randomBgGrandientDarkStyle()
    const style = {
      background,
    }
    return <div className={`bg ${className}`} style={style} />
  }
  return <div className={`bg ${className}`} />
}
