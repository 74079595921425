import React from 'react'
import {withRouter} from 'react-router-dom'
import {CssBaseline} from '@material-ui/core'
import Navbar from './Navbar'
import Main from './Main'
import Footer from './Footer'
import Background from './Background'

function Body(props) {
  return (
    <>
      <CssBaseline />
      <Navbar />
      <Main />
      <Footer />
      <Background random />
    </>
  )
}
export default withRouter(Body)
