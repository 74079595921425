import React from 'react'
import {useTranslation} from 'react-i18next'
import Head from '../component/Head'
import {fromMd,animated} from '../Util'
import Constant from '../Constant'

export default function Privacy(props) {
  const {name,about} = Constant
  const {link,email,country} = about

  const {t} = useTranslation()
  const md = t(`privacy`,{name,link,email,country})

  return (
    <div className="container mb-5">
      <h1 className={`text-white text-center mb-3 ${animated('fadeInDown','slow')}`}>{t('Privacy Policy')}</h1>
      <Head className={`p-5 ${animated('fadeInUp')}`}>
        <div dangerouslySetInnerHTML={fromMd(md)} />
      </Head>
    </div>
  )
}

/*
import React from 'react'
import {useTranslation} from 'react-i18next'
import DataService from '../component/DataService'
import Head from '../component/Head'
import {fromMd,fromTemplate} from '../Util'
import Constant from '../Constant'

export default function Privacy(props) {
  const [init,setInit] = React.useState(false)
  const [template,setTemplate] = React.useState()
  const [content,setContent] = React.useState()
  const [message,setMessage] = React.useState()

  React.useEffect(()=>{
    if (!init) {
      setInit(true)
      const url = '/md/privacy.md'
      DataService.text(url, data=>{
        if (typeof(data)==='string'&&data.length>0) {
          setTemplate(data)
        } else {
          setMessage({type:'danger',value:'Something went wrong'})
        }
      }, err=>{
        setMessage({type:'danger',value:'Something went wrong'})
      })
    }
  }, [init])

  React.useState(()=>{
    if (typeof(message)==='object'&&message!==null) {
      const {type} = message
      if (type==='danger') setInit(false)
    }
  }, [message])

  React.useEffect(()=>{
    if (typeof(template)==='string'&&template.length>0) {
      const {name,about} = Constant
      const {link,email,country} = about
      const content = fromTemplate(template)({name,link,email,country})
      if (typeof(content)==='string'&&content.length>0) {
        setContent(content)
      }
    }
  }, [template])

  const renderContent = ()=>{
    if (typeof(content)==='string'&&content.length>0) {
      return (
        <div dangerouslySetInnerHTML={fromMd(content)} />
      )
    }
    return null
  }

  return (
    <div className="container mb-5">
      <h1 className="text-white text-center mb-3">Privacy Policy</h1>
      <Head className="p-5">{renderContent()}</Head>
    </div>
  )
}*/
