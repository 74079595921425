import React from 'react'
import {useTranslation} from 'react-i18next'
import Properties from '../component/Properties'
import Gallery from '../component/Gallery'
import {A,animated} from '../Util'

const INFO = {
  name: 'Let\'s Learn ABC',
  url: 'https://apps.apple.com/us/app/lets-learn-abc/id1473677673',
  images: [
    '/img/abc/iphone-8-plus/01-intro.png',
    '/img/abc/iphone-8-plus/02-abc.png',
    '/img/abc/iphone-8-plus/03-a.png',
    '/img/abc/iphone-8-plus/04-b.png',
    '/img/abc/iphone-8-plus/05-c.png',
  ],
  properties: {
    "about this app": `This App is created with \u{1f496} to help anyone to learn or teach our loved ones to read \u{1f4d6}`,
    "promotional text": `Learn or teach our loved ones to read`,
    description: `As a first step in learning, Let's Learn ABC is introducing alphabets. Let's Learn ABC helps to learn ABC by associating.

The app presents a set of cards on learning every letter. On dragging the letter to each card, the card image will be revealed and the word on the card will be pronounced. This helps anyone to learn each letter by associating the word pronounced with the letter.

Let's Learn ABC is the first from Let's Learn series of apps. Let's learn series are Educational apps built to help learning and teaching our loved ones.`,
    keywords: ['lets learn','learn','abc','read','reading','english','language','phonic'],
    "year released": 2019,
  },
}

export default function LetsLearnABC(props) {
  const {name,images,properties,url} = INFO
  const {t} = useTranslation()
  return (
    <div className="mb-5">
      <h1 className={`text-white text-center ${animated('slideInDown')}`}>
        <img src="/img/abc/icon.png" alt={name} className="app-icon mr-3 shadow" />
        <span>{name}</span>
      </h1>

      <div className="text-center mb-5">
        <a href={url} target="_blank" className="download-appstore-btn" alt="Download from App Store!" rel="noopener noreferrer">&nbsp;</a>
      </div>

      <div className="text-center mb-5">
        <Gallery value={images} className="app-gallery" />
      </div>

      <div className="p-3 container bg-light rounded shadow">
        <Properties value={properties} center />

        <div className="text-center">
          <label className="text-uppercase font-weight-bold">{t('credits')}</label>
          <div>
            <A blank href="https://pocket-se.info" alt="Pocket sound / Sound effect material"><img src="/img/pocket-se/bnr_pocket-se.jpg" alt="Pocket sound / Sound effect material" /></A>
          </div>
        </div>
      </div>
    </div>
  )
}
