import React from 'react'
import {useTranslation} from 'react-i18next'
import Properties from '../component/Properties'
import Gallery from '../component/Gallery'
import {A,animated} from '../Util'

const INFO = {
  name: 'Ayo Belajar ABC',
  url: 'https://apps.apple.com/us/app/ayo-belajar-abc/id1476412853',
  images: [
    '/img/ayo/iphone-8-plus/01-intro.png',
    '/img/ayo/iphone-8-plus/02-abc.png',
    '/img/ayo/iphone-8-plus/03-a.png',
    '/img/ayo/iphone-8-plus/04-b.png',
    '/img/ayo/iphone-8-plus/05-c.png',
  ],
  properties: {
    "about this app": `Aplikasi ini dibina dengan \u{1f496} bagi membantu siapa saja belajar atau mengajar orang yang kita sayangi membaca \u{1f4d6}`,
    "promotional text": `Learn or teach our loved ones to read`,
    /*description: `Sebagai langkah pertama dalam belajar, Ayo Belajar ABC memperkenalkan abjad. Ayo Belajar ABC membantu mempelajari ABC dengan menghubungkan.

Aplikasi ini menyajikan satu set kartu untuk mempelajari setiap abjad. Dengan seretkan abjad ke setiap kartu, gambar kartu akan terungkap dan kata pada kartu akan diucapkan. Ini membantu siapa saja untuk mempelajari setiap abjad dengan mengaitkan kata yang diucapkan dengan abjad itu.

Ayo Belajar ABC adalah dari rangkaian Aplikasi Ayo Belajar (Let's Learn). Mari kita pelajari seri aplikasi pendidikan yang dibangun untuk membantu belajar dan mengajar orang yang kita sayangi.`,*/
    description: `Sebagai langkah pertama dalam belajar, Ayo Belajar ABC memperkenalkan abjad. Ayo Belajar ABC membantu mempelajari ABC dengan menghubungkan.

Aplikasi ini menyajikan satu set kartu untuk mempelajari setiap abjad. Dengan seretkan abjad ke setiap kartu, gambar kartu akan terungkap dan kata pada kartu akan diucapkan. Ini membantu siapa saja untuk mempelajari setiap abjad dengan mengaitkan kata yang diucapkan dengan abjad itu.

Ayo Belajar ABC adalah dari rangkaian Aplikasi Ayo Belajar (Let's Learn). Ayo Belajar adalah seri aplikasi pendidikan yang dibangun untuk membantu belajar dan mengajar orang yang kita sayangi.`,
    keywords: ['lets learn','ayo belajar','learn','belajar','abc','read','baca','reading','membaca','indonesian','language','bahasa','phonic','fonik'],
    "year released": 2019,
  }
}

export default function AyoBelajarABC(props) {
  const {name,images,properties,url} = INFO
  const {t} = useTranslation()
  return (
    <div className="mb-5">
      <h1 className={`text-white text-center ${animated('slideInDown')}`}>
        <img src="/img/ayo/Icon-196.png" alt={name} className="app-icon mr-3 shadow" />
        <span>{name}</span>
      </h1>

      <div className="text-center mb-5">
        <a href={url} target="_blank" className="download-appstore-btn" alt="Download from App Store!" rel="noopener noreferrer">&nbsp;</a>
      </div>

      <div className="text-center mb-5">
        <Gallery value={images} className="app-gallery" />
      </div>

      <div className="p-3 container bg-light rounded shadow">
        <Properties value={properties} center />

        <div className="text-center">
          <label className="text-uppercase font-weight-bold">{t('credits')}</label>
          <div>
            <A blank href="https://pocket-se.info" alt="Pocket sound / Sound effect material"><img src="/img/pocket-se/bnr_pocket-se.jpg" alt="Pocket sound / Sound effect material" /></A>
          </div>
        </div>
      </div>
    </div>
  )
}
