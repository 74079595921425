import React from 'react'
import {Modal} from '@material-ui/core'
import {animated} from '../Util'

export default function Gallery(props) {
  const {value,className=''} = props
  const [open,setOpen] = React.useState(false)
  const [src,setSrc] = React.useState()
  React.useEffect(()=>{
    if (typeof(src)==='string'&&src.length>0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [src])
  if (Array.isArray(value)&&value.length>0) {
    const total = value.length
    const handleClose = ()=>{
      setSrc(null)
    }
    const prev = e=>{
      const i = value.indexOf(src)
      if (i>=0) {
        const j = i===0?value.length-1:i-1
        setSrc(value[j])
      }
    }
    const next = e=>{
      const i = value.indexOf(src)
      if (i>=0) {
        const j = i===value.length-1?0:i+1
        setSrc(value[j])
      }
    }
    return (
      <div className={`Gallery ${className} ${animated('fadeInDown','slow')}`}>
        {
          value.map((e,i)=>{
            if (typeof(e)==='string'&&e.length>0) {
              return (
                <button key={i} onClick={()=>setSrc(e)}>
                  <img src={e} alt={`${i+1} of ${total}`} />
                </button>
              )
            }
            return null
          })
        }
        <Modal aria-labelledby="Gallery" aria-describedby="Gallery" open={open} onClose={handleClose}>
          <div className={`text-center app-gallery-zoom ${animated()}`}>
            <button className="btn btn-outline-light m-2 shadow" onClick={handleClose}>{'\u2715'}</button>
            <div>
              <button className="btn btn-primary mr-2 shadow" onClick={prev}>{'\u25C4'}</button>
              {src&&<img src={src} alt="Zoom" className="rounded shadow" />}
              <button className="btn btn-primary ml-2 shadow" onClick={next}>{'\u25ba'}</button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
  return null
}
