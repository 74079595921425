import React from 'react'
import Hero from '../component/Hero'
import List from '../component/List'
import Head from '../component/Head'
import Constant from '../Constant'
import {useTranslation} from 'react-i18next'
import {animated} from '../Util'

export default function Home(props) {
  const {icon,name,lead,pages} = Constant
  const {t} = useTranslation()
  return (
    <>
      <Head className={`mb-5 ${animated()}`}>
        <Hero icon={icon} title={name} lead={lead} />
      </Head>
      <h3 className={`text-center mb-3 ${animated()}`}>{t('Apps for Future Thinker')}</h3>
      <List title={`${t('Let\'s Learn Series')}`} value={pages.filter(e=>!e.hide)} />
    </>
  )
}
