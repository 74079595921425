import React from 'react'
import {Chip} from '@material-ui/core'
import {isUrl,isMultiline,animated} from '../Util'
import {useTranslation} from 'react-i18next'

export default function Properties(props) {
  const {t} = useTranslation()
  const {value,className='',center=false} = props
  const classNames = [className]
  if (center) classNames.push('text-center')
  if (typeof(value)==='object'&&value!==null) {
    return (
      <div className={classNames.join(' ')}>
        {
          Object.keys(value).map((e,i)=>{
            const v = value[e]
            const render = ()=>{
              if (isUrl(v)) {
                return (
                  <a href={v} target="_blank" rel="noopener noreferrer">{v}</a>
                )
              }
              if (isMultiline(v)) {
                return (
                  <div>{v.split('\n').map((e,i)=><p key={i}>{e}</p>)}</div>
                )
              }
              if (Array.isArray(v)) {
                return (
                  <div>{v.map((e,i)=>(
                    <Chip key={i} label={e} className="m-1 shadow" />
                  ))}</div>
                )
              }
              return (
                <p className="lead">{t(v)}</p>
              )
            }
            return (
              <div key={i} className={`mb-4 ${animated()}`}>
                <label className="text-uppercase font-weight-bold">{t(e)}</label>
                {render(v)}
              </div>
            )
          })
        }
      </div>
    )
  }
  return null
}
