const Constant = {
  languages: ['en','id','my'],
  pages: [
    {icon: '\u{1f984}', name: 'Home', path: '/', lead: '', component: require('./page/Home').default, hide:true},
    {icon: '\u{1f984}', name: 'About', path: '/about', lead: '', component: require('./page/About').default, hide:true},
    {icon: '\u{1f984}', name: 'Album', path: '/album', lead: '', component: require('./page/Album').default, hide:true},
    {
      icon: '\u{1f984}', name: 'Let\'s Learn ABC', path: '/lets-learn-abc', lead: 'Learning how to read', component: require('./page/LetsLearnABC').default,
      //src: '/img/abc/icon.png',
      src: '/img/abc/iphone-8-plus/01-intro.png',
    },
    {
      icon: '\u{1f984}', name: 'Ayo Belajar ABC', path: '/ayo-belajar-abc', lead: `Belajar membaca Bahasa Indonesia \u{1f1ee}\u{1f1e9}`, component: require('./page/AyoBelajarABC').default,
      //src: '/img/ayo/Icon-196.png',
      src: '/img/ayo/iphone-8-plus/01-intro.png',
    },
    {icon: '\u{1f984}', name: 'Privacy Policy', path: '/privacy', lead: 'Privacy Policy', component: require('./page/Privacy').default, hide:true},
    {icon: '\u{1f984}', name: 'Terms and Conditions', path: '/terms', lead: 'Terms and Conditions', component: require('./page/Terms').default, hide:true},
  ],
  icon: '\u{1f984}',
  name: 'Thinker Tech',
  lead: 'Let\'s think \u{1f914} together to make \u{1f30f} better tomorrow \u{1f680}',
  about: {
    link: 'https://www.thinkertech.io',
    email: 'nieylarm+thinkertech@gmail.com',
    country: '\u{1f1f2}\u{1f1fe} Malaysia',
    founded: 2019,
  },
}

export default Constant
