import React from 'react'
import marked from 'marked'

export function capFirstChar(s) {
  if (typeof(s)==='string'&&s.length>0) {
    return s.charAt(0).toUpperCase().concat(s.slice(1))
  }
  return ''
}

export function isUrl(s) {
  if (typeof(s)==='string'&&s.length>0) {
    return s.startsWith('http')&&s.indexOf('://')>0
  }
  return false
}

export function isMultiline(s) {
  if (typeof(s)==='string'&&s.length>0) {
    return s.indexOf('\n')>=0
  }
  return false
}

export function randomInt(a=10,b=0) {
  return Math.floor(Math.random()*a)+b
}

// randomInt alias
export function randInt(a,b) {
  return randomInt(a,b)
}

export function randomChar(upper=false) {
  const abc = 'abcdefghijklmnopqrstuvwxyz'
  const c = abc[Math.floor(Math.random()*abc.length)]
  return upper?c.toUpperCase():c
}

export function randomWord(l=10) {
  return (new Array(l)).fill(0).map(e=>randomChar()).join('')
}

export function fromMd(v) {
  if (typeof(v)==='string') {
    marked.setOptions({
      gfm: true,
    })
    return {__html:marked(v)}
  }
  return {__html:''}
}

// eg: fromTemplate('hi, ${name}!')({name:'john'})
// FIXME: The Function constructor is eval  no-new-func
/*export function fromTemplate(s) {
  return data=>new Function(`{${Object.keys(data).join(',')}}`, 'return `' + s + '`')(data)
}*/

// colors with luminance black grouped by basic colors
export const LightColor = {
  Pink: ['Pink','LightPink'],
  Yellow: ['Yellow','LightYellow','LemonChiffon','LightGoldenrodYellow','PapayaWhip','Moccasin','PeachPuff','PaleGoldenrod','Khaki','DarkKhaki','Gold'],
  Brown: ['Cornsilk','BlanchedAlmond','Bisque','NavajoWhite','Wheat','Burlywood'],
  Green: ['Chartreuse','GreenYellow','LightGreen','PaleGreen'],
  Cyan: ['LightCyan','PaleTurquoise','Aquamarine'],
  Blue: ['LightSteelBlue','PowderBlue','LightBlue','SkyBlue','LightSkyBlue'],
  Purple: ['Lavender','Thistle'],
  White: ['White','Snow','Honeydew','MintCream','Azure','AliceBlue','GhostWhite','WhiteSmoke','Seashell','Beige','OldLace','FloralWhite','Ivory','AntiqueWhite','Linen','LavenderBlush','MistyRose'],
  Gray: ['Gainsboro','LightGray','Silver'],
}

export function randColor(props) {
  const keys = Object.keys(LightColor)
  const index = randomInt(keys.length)
  const list = LightColor[keys[index]]
  return list[randomInt(list.length)]
}

// colors with luminance white grouped by basic colors
const DarkColor = {
  Pink: [/*'HotPink',*/'DeepPink','PaleVioletRed','MediumVioletRed'],
  Red: [/*'LightSalmon','Salmon','DarkSalmon','LightCoral',*/'IndianRed','Crimson','Firebrick','DarkRed','Red'],
  Orange: ['OrangeRed','Tomato','Coral','DarkOrange','Orange'],
  Yellow: ['DarkKhaki'],
  Brown: [/*'Tan',*/'RosyBrown','SandyBrown','Goldenrod','DarkGoldenrod','Peru','Chocolate','SaddleBrown','Sienna','Brown','Maroon'],
  Green: ['DarkOliveGreen','Olive','OliveDrab',/*'YellowGreen','LimeGreen','Lime','LawnGreen','SpringGreen','MediumSpringGreen','DarkSeaGreen','MediumAquamarine','MediumSeaGreen',*/'SeaGreen','ForestGreen','Green','DarkGreen'],
  Cyan: [/*'Aqua','Cyan','Turquoise','MediumTurquoise','DarkTurquoise',*/'LightSeaGreen','CadetBlue','DarkCyan','Teal'],
  Blue: [/*'DeepSkyBlue',*/'DodgerBlue','CornflowerBlue','SteelBlue','RoyalBlue','Blue','MediumBlue','DarkBlue','Navy','MidnightBlue'],
  Purple: [/*'Plum','Violet',*/'Orchid','Fuchsia','Magenta','MediumOrchid','MediumPurple','BlueViolet','DarkViolet','DarkOrchid','DarkMagenta','Purple','Indigo','DarkSlateBlue','SlateBlue','MediumSlateBlue'],
  Gray: [/*'DarkGray',*/'Gray','DimGray','LightSlateGray','SlateGray','DarkSlateGray','Black'],
}

export function randColor2(props) {
  const keys = Object.keys(DarkColor)
  const index = randomInt(keys.length)
  const list = DarkColor[keys[index]]
  return list[randomInt(list.length)]
}

export function randomBgGrandientLightStyle() {
  const a = randColor()
  const b = randColor()
  const n = randomInt(360)
  return `linear-gradient(${n}deg,${a},${b})`
}

export function randomBgGrandientDarkStyle() {
  const a = randColor2()
  const b = randColor2()
  const n = randomInt(360)
  return `linear-gradient(${n}deg,${a},${b})`
}

export const Effect = {
  all: ['bounce','flash','pulse','rubberBand','shake','headShake','swing','tada','wobble','jello','bounceIn','bounceInDown','bounceInLeft','bounceInRight','bounceInUp','bounceOut','bounceOutDown','bounceOutLeft','bounceOutRight','bounceOutUp','fadeIn','fadeInDown','fadeInDownBig','fadeInLeft','fadeInLeftBig','fadeInRight','fadeInRightBig','fadeInUp','fadeInUpBig','fadeOut','fadeOutDown','fadeOutDownBig','fadeOutLeft','fadeOutLeftBig','fadeOutRight','fadeOutRightBig','fadeOutUp','fadeOutUpBig','flipInX','flipInY','flipOutX','flipOutY','lightSpeedIn','lightSpeedOut','rotateIn','rotateInDownLeft','rotateInDownRight','rotateInUpLeft','rotateInUpRight','rotateOut','rotateOutDownLeft','rotateOutDownRight','rotateOutUpLeft','rotateOutUpRight','hinge','jackInTheBox','rollIn','rollOut','zoomIn','zoomInDown','zoomInLeft','zoomInRight','zoomInUp','zoomOut','zoomOutDown','zoomOutLeft','zoomOutRight','zoomOutUp','slideInDown','slideInLeft','slideInRight','slideInUp','slideOutDown','slideOutLeft','slideOutRight','slideOutUp','heartBeat'],
  attentionSeekers: ["bounce","flash","pulse","shake","headShake","swing","tada","wobble","jello","heartBeat"],
}

Effect.in = Effect.all.filter(e=>e.indexOf('In')>=0)
Effect.out = Effect.all.filter(e=>e.indexOf('Out')>=0).concat('hinge')

export function randomEffect() {
  //const list = Effect.attentionSeekers
  const list = Effect.in
  const index = Math.floor(Math.random()*list.length)
  return list[index]
}

export function randomAttentionSeeker() {
  const list = Effect.attentionSeekers
  const index = Math.floor(Math.random()*list.length)
  return list[index]
}

export function animated(effect,speed) {
  if (Effect.all.indexOf(effect)>=0) {
    if (['slow','slower','fast','faster'].indexOf(speed)>=0) {
      return `animated ${effect} ${speed}`
    }
    return `animated ${effect}`
  }
  return animated(randomEffect(),'faster')
}

export function generateId(prefix='_',l=6) {
  return `${prefix}${Date.now()}${(new Array(l)).fill(0).map(e=>randomInt()).join('')}`
}

export function whatos() {
  let os = 'NA'
  if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) os = 'Windows 10'
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) os = 'Windows 8'
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) os = 'Windows 7'
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) os = 'Windows Vista'
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) os = 'Windows XP'
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) os = 'Windows 2000'
  if (window.navigator.userAgent.indexOf('Mac') !== -1) os = 'Mac/iOS'
  if (window.navigator.userAgent.indexOf('X11') !== -1) os = 'UNIX'
  if (window.navigator.userAgent.indexOf('Linux') !== -1) os = 'Linux'
  return os
}

export const COLOR = {
  Pink: ['Pink', 'LightPink', 'HotPink', 'DeepPink', 'PaleVioletRed', 'MediumVioletRed',],
  Red: ['LightSalmon', 'Salmon', 'DarkSalmon', 'LightCoral', 'IndianRed', 'Crimson', 'Firebrick', 'DarkRed', 'Red',
  ],
  Orange: ['OrangeRed', 'Tomato', 'Coral', 'DarkOrange', 'Orange',],
  Yellow: ['Yellow', 'LightYellow', 'LemonChiffon', 'LightGoldenrodYellow', 'PapayaWhip', 'Moccasin', 'PeachPuff', 'PaleGoldenrod', 'Khaki', 'DarkKhaki', 'Gold',],
  Brown: ['Cornsilk', 'BlanchedAlmond', 'Bisque', 'NavajoWhite', 'Wheat', 'Burlywood', 'Tan', 'RosyBrown', 'SandyBrown', 'Goldenrod', 'DarkGoldenrod', 'Peru', 'Chocolate', 'SaddleBrown', 'Sienna', 'Brown', 'Maroon',],
  Green: ['DarkOliveGreen', 'Olive', 'OliveDrab', 'YellowGreen', 'LimeGreen', 'Lime', 'LawnGreen', 'Chartreuse', 'GreenYellow', 'SpringGreen', 'MediumSpringGreen', 'LightGreen', 'PaleGreen', 'DarkSeaGreen', 'MediumAquamarine', 'MediumSeaGreen', 'SeaGreen', 'ForestGreen', 'Green', 'DarkGreen',],
  Cyan: ['Aqua', 'Cyan', 'LightCyan', 'PaleTurquoise', 'Aquamarine', 'Turquoise', 'MediumTurquoise', 'DarkTurquoise', 'LightSeaGreen', 'CadetBlue', 'DarkCyan', 'Teal'],
  Blue: ['LightSteelBlue', 'PowderBlue', 'LightBlue', 'SkyBlue', 'LightSkyBlue', 'DeepSkyBlue', 'DodgerBlue', 'CornflowerBlue', 'SteelBlue', 'RoyalBlue', 'Blue', 'MediumBlue', 'DarkBlue', 'Navy', 'MidnightBlue'],
  Purple: ['Lavender', 'Thistle', 'Plum', 'Violet', 'Orchid', 'Fuchsia', 'Magenta', 'MediumOrchid', 'MediumPurple', 'BlueViolet', 'DarkViolet', 'DarkOrchid', 'DarkMagenta', 'Purple', 'Indigo', 'DarkSlateBlue', 'SlateBlue', 'MediumSlateBlue'],
  White: ['White', 'Snow', 'Honeydew', 'MintCream', 'Azure', 'AliceBlue', 'GhostWhite', 'WhiteSmoke', 'Seashell', 'Beige', 'OldLace', 'FloralWhite', 'Ivory', 'AntiqueWhite', 'Linen', 'LavenderBlush', 'MistyRose'],
  Gray: ['Gainsboro', 'LightGray', 'Silver', 'DarkGray', 'Gray', 'DimGray', 'LightSlateGray', 'SlateGray', 'DarkSlateGray', 'Black'],
}

export function isColor(c) {
  if (typeof(c)==='string'&&c.length>0) {
    const keys = Object.keys(COLOR)
    for (let i=0;i<keys.length;i++) {
      const list = COLOR[keys[i]]
      for (let j=0;j<list.length;j++) {
        if (c.toLowerCase()===list[j].toLowerCase()) return true
      }
    }
  }
  return false
}

export class Color {
  static black = new Color('#000000')
  static white = new Color('#ffffff')
  constructor(value) {
    if (typeof(value)==='string'&&value.length>0) {
      this.fromString(value)
    } else {
      // rgb value is between 0-255, a (alpha) is between 0.0-1.0
      this.r = 0
      this.g = 0
      this.b = 0
      this.a = 1
    }
  }
  inverse() {
    const {r,g,b} = this
    const max = 255
    const rgb = [max-r,max-g,max-b]
    return new Color(`rgb(${rgb.join(',')})`)
  }
  luminance() {
    const {r,g,b} = this
    const L = r*.299+g*.587+b*.114
    if (L>186) return Color.black
    return Color.white
  }
  lumin() {
    //const {h,s,l} = this.toHsl()
    //return s>50?Color.black:Color.white
    return this.luminance()
  }
  fromString(s) {
    if (typeof(s)==='string'&&s.length>0) {
      if (s.charAt(0)==='#') {
        const rgb = s.slice(1)
        this.r = parseInt(rgb.slice(0,2),16)
        this.g = parseInt(rgb.slice(2,4),16)
        this.b = parseInt(rgb.slice(4,6),16)
        if (typeof(this.a)!=='number') {
          this.a = 1
        }
      } else if (s.startsWith('rgba')) {
        const rgba = s.trim().slice(5,s.length-1).split(',')
        const rgb = rgba.slice(0,rgba.length-1).map(e=>parseInt(e))
        this.r = rgb[0]
        this.g = rgb[1]
        this.b = rgb[2]
        this.a = parseFloat(rgba.slice(rgba.length-1))
      } else if (s.startsWith('rgb')) {
        const rgb = s.trim().slice(4,s.length-1).split(',').map(e=>parseInt(e))
        this.r = rgb[0]
        this.g = rgb[1]
        this.b = rgb[2]
        if (typeof(this.a)!=='number') {
          this.a = 1
        }
      }
    }
    return this
  }
  toHsl() {
    const r = parseFloat(this.r)/255
    const g = parseFloat(this.g)/255
    const b = parseFloat(this.b)/255
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const diff = max - min
    const add = max + min
    const hue = min===max?0:r===max?((60*(g-b)/diff)+360)%360:g===max?(60*(b-r)/diff)+120:(60*(r-g)/diff)+240
    const lum = 0.5*add
    const sat = lum===0?0:lum===1?1:lum<=0.5?diff/add:diff/(2-add)
    const h = Math.round(hue)
    const s = Math.round(sat*100)
    const l = Math.round(lum*100)
    return {h,s,l}
  }
  hsl() {
    const {h,s,l} = this.toHsl()
    return `hsl(${h}, ${s}%, ${l}%)`
  }
  hsla() {
    const {h,s,l} = this.toHsl()
    const a = parseFloat(this.a)||1
    return `hsla(${h}, ${s}%, ${l}%, ${a})`
  }
  toString(p) {
    const {r,g,b,a} = this
    if (p==='hex'||p==='#') {
      const toHex=n=>{
        const hex = n.toString(16)
        if (hex.length===1) return `0${hex}`
        return hex
      }
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`
    }
    if (p==='rgb') {
      return `rgb(${r},${g},${b})`
    }
    if (p==='hsl') {
      return this.hsl()
    }
    if (p==='hsla') {
      return this.hsla()
    }
    return `rgba(${r},${g},${b},${a})`
  }
}

export function randomColorGroup() {
  const keys = Object.keys(COLOR)
  return keys[randomInt(keys.length)]
}

export function randomColor(n='') {
  const randomInt=(a=10,b=0)=>{
    return Math.floor(Math.random()*a)+b
  }

  const keys = Object.keys(COLOR)
  const l = keys.length
  const i = randomInt(l)
  let k = keys[i]
  if (typeof(n)==='string' && n.length>0) {
    const j = keys.indexOf(n)
    if (j>=0) {
      k = keys[j]
    }
  }

  const colors = COLOR[k]
  return colors[randomInt(colors.length)]
}

export function trace(value,size) {
  if (typeof(size)==='number'&&size>0) {
    console.log(JSON.stringify(value,null,size))
  } else {
    console.log(value)
  }
}

export function todate() {
  return (new Date()).toISOString().split('T')[0]
}

export function lipsum(n=10,dot=false) {
  if (typeof(n)!=='number'||(typeof(n)==='number'&&n<1)) n=10
  const words = ['lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit', 'vivamus', 'et', 'accumsan', 'augue', 'duis', 'eget', 'nunc', 'id', 'sodales', 'finibus', 'vestibulum', 'sagittis', 'magna', 'nec', 'rutrum', 'volutpat', 'risus', 'tincidunt', 'justo', 'non', 'gravida', 'tortor', 'enim', 'in', 'urna', 'ut', 'vel', 'metus', 'pellentesque', 'porttitor', 'vitae', 'nisi', 'nullam', 'faucibus', 'condimentum', 'quam', 'imperdiet', 'class', 'aptent', 'taciti', 'sociosqu', 'ad', 'litora', 'torquent', 'per', 'conubia', 'nostra', 'inceptos', 'himenaeos', 'interdum', 'malesuada', 'fames', 'ac', 'ante', 'primis', 'curabitur', 'nibh', 'quis', 'iaculis', 'cras', 'mollis', 'eu', 'congue', 'leo']
  const count = n //Math.floor(Math.random()*n)+1
  const sentence = []
  const indexes = (new Array(count)).fill(0).map(index=>Math.floor(Math.random()*words.length))
  indexes.forEach((index,i)=>{
    const word = words[index]
    if (i===0)
      sentence.push(word.charAt(0).toUpperCase()+word.substr(1))
    else
      sentence.push(word)
  })
  if (dot) return sentence.join(' ').concat('.')
  return sentence.join(' ')
}

export function isPrime(n) {
  if (typeof(n)==='number') {
    for (let i=2;i<=Math.sqrt(n);i++) {
      if (n%i===0) {
        return false
      }
    }
    return n>1
  }
  return false
}

/* React component */

export function PreCode(props) {
  const {children,value,size=2,className='',sm=false} = props
  const style = {}
  if (sm) style.fontSize = 'x-small'
  if (typeof(value)==='string') {
    return (
      <div className={className}><pre style={style}><code>{value.trim()}</code></pre></div>
    )
  }
  if (typeof(value)==='object' || typeof(value)==='number' || typeof(value)==='boolean') {
    return (
      <div className={className}><pre style={style}><code>{JSON.stringify(value,null,size)}</code></pre></div>
    )
  }
  if (typeof(children)==='string') {
    return (
      <div className={className}><pre style={style}><code>{children.trim()}</code></pre></div>
    )
  }
  return (
    <div className={className}><pre style={style}><code>{JSON.stringify(children,null,size)}</code></pre></div>
  )
}

export function A(props) {
  const {href,target,blank=false,className='',alt='',children} = props
  const el = {href,className,alt}
  if (blank) el.target = '_blank'
  if (target==='_blank'||blank) el.rel = 'noopener noreferrer'
  return (
    <a {...el}>{children}</a>
  )
}
