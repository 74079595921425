import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Container,Typography,Grid,Button} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 2),
  },
  heroButtons: {
    marginTop: theme.spacing(2),
  },
}))

export default function Hero(props) {
  const {icon,title,lead,primary,secondary} = props

  const classes = useStyles()

  const renderPrimary = ()=>{
    if (typeof(primary)==='object'&&primary!==null) {
      const {label,onClick} = primary
      return (
        <Button variant="contained" color="primary" onClick={onClick}>{label}</Button>
      )
    }
    return null
  }

  const renderSecondary = ()=>{
    if (typeof(secondary)==='object'&&secondary!==null) {
      const {label,onClick} = secondary
      return (
        <Button variant="outlined" color="primary" onClick={onClick}>{label}</Button>
      )
    }
    return null
  }

  const hasButton = ()=>{
    return typeof(primary)==='object'&&primary!==null&&typeof(secondary)==='object'&&secondary!==null
  }

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        {icon&&<Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>{icon}</Typography>}
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>{title}</Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>{lead}</Typography>
        {hasButton()&&<div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              {renderPrimary()}
            </Grid>
            <Grid item>
              {renderSecondary()}
            </Grid>
          </Grid>
        </div>}
      </Container>
    </div>
  )
}
