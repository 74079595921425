import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {Container,Grid,Card,CardMedia,CardContent,Typography,Button,CardActions} from '@material-ui/core'
import {animated,randomBgGrandientLightStyle} from '../Util'

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}))

export default function List(props) {

  const {title,value} = props

  const classes = useStyles()

  const {t} = useTranslation()

  const renderList = ()=>{
    if (Array.isArray(value)&&value!==null) {
      return value.map((e,i)=>{
        const {name,lead,path,src} = e
        //const src = 'https://picsum.photos/300/225'
        return (
          <Grid item key={i} xs={12} sm={6} md={4}>
            <Card className={`${classes.card} ${animated()} shadow`}>
              {/*<CardMedia className={classes.cardMedia} image={src} title="Unsplash" />*/}
              <Link to={path}>
                <div className="preview-container" style={{background:randomBgGrandientLightStyle()}}>
                  <CardMedia className={`${classes.cardMedia} preview`} image={src} title={name} />
                </div>
              </Link>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">{name}</Typography>
                <Typography>{t(lead)}</Typography>
              </CardContent>
              <CardActions>
                <Link to={path}><Button size="small" color="primary">{t('View')}</Button></Link>
              </CardActions>
            </Card>
          </Grid>
        )
      })
    }
    return null
  }

  return (
    <Container className="p-3" maxWidth="md">
      {title&&<Typography component="h3" variant="h4" align="center" color="textPrimary" gutterBottom className={animated()}>{title}</Typography>}
      <Grid container spacing={3}>
        {renderList()}
      </Grid>
    </Container>
  )
}
