import React from 'react'
import {Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {Typography,Button} from '@material-ui/core'
import Constant from '../Constant'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
}))

function MadeWithLove() {
  const {icon,name} = Constant
  const {t} = useTranslation()
  return (
    <Typography variant="body2" color="textSecondary" align="center">{t('Built with')} <span className="font-weight-bold" style={{color:'red'}}>{'\u2661'}</span> {t('by')} {icon} {name}</Typography>
  )
}

export default function Footer(props) {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom>

      </Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">

      </Typography>

      <div className="text-center mb-3">
        <Link to={'/privacy'} className="p-2">
          <Button color="inherit">{t('Privacy Policy')}</Button>
        </Link>
        <Link to={'/terms'} className="p-2">
          <Button color="inherit">{t('Terms and Conditions')}</Button>
        </Link>
      </div>

      <MadeWithLove />
    </footer>
  )
}
